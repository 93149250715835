import React from "react"
import Page from "../../../components/page"
import ResponsiveImageGrid from "../../../components/responsive-image-grid"
import ScrollDown from "../../../components/scroll-down"
import { themes } from "../../../styles/themes"
import paths from "../../../utils/paths"
import useScreenSize from "../../../utils/useScreenSize"

const imTheWildOneGrid = (labels, isSmallScreen) => [
  {
    type: "grid",
    orientation: "horizontal",
    grid: isSmallScreen
      ? [
          {
            type: "image",
            weight: 49.25,
            src: "/images/im-the-wild-one/easy-rider-handle-detail.jpg",
            text: isSmallScreen
              ? undefined
              : labels.imTheWildOneReadyForAdventure,
            textClass: "im-the-wild-one-ready-for-adventure-text",
            alt: labels.imTheWildOneEasyRiderHandleAlt,
            imgTitle: labels.easyRiderImgTitle
          },
          {
            type: "html",
            weight: 49.25,
            html: (
              <div className="paragraph-div-centered-relatively im-the-wild-one-ready-for-adventure-text-container">
                <p className="im-the-wild-one-ready-for-adventure-text">
                  {labels.imTheWildOneReadyForAdventure}
                </p>
              </div>
            )
          }
        ]
      : [
          {
            type: "image",
            weight: 100,
            src: "/images/im-the-wild-one/easy-rider-handle-detail.jpg",
            text: isSmallScreen
              ? undefined
              : labels.imTheWildOneReadyForAdventure,
            textClass: "im-the-wild-one-ready-for-adventure-text",
            alt: labels.imTheWildOneEasyRiderHandleAlt,
            imgTitle: labels.easyRiderImgTitle
          }
        ]
  },
  {
    type: "grid",
    orientation: "horizontal",
    margin: isSmallScreen,
    grid: [
      {
        type: "image",
        weight: 30,
        src: "/images/im-the-wild-one/easy-rider.jpg",
        title: labels.imTheWildOneEasyRiderTitle,
        alt: labels.imTheWildOneEasyRiderAlt,
        imgTitle: labels.easyRiderImgTitle
      },
      {
        type: "image",
        weight: 68.5,
        src: "/images/im-the-wild-one/easy-rider-bag-detail.jpg",
        alt: labels.imTheWildOneEasyRiderBagAlt,
        imgTitle: labels.easyRiderImgTitle
      }
    ]
  },
  {
    type: "grid",
    orientation: "horizontal",
    margin: isSmallScreen,
    grid: isSmallScreen
      ? [
          {
            type: "image",
            weight: 49.25,
            src: "/images/im-the-wild-one/mustang-body-detail.jpg",
            text: isSmallScreen ? undefined : labels.imTheWildOneTouchFeelEnjoy,
            textClass: "im-the-wild-one-touch-feel-enjoy-text",
            alt: labels.imTheWildOneMustangBodyAlt,
            imgTitle: labels.mustangImgTitle
          },
          {
            type: "html",
            weight: 49.25,
            html: (
              <div className="paragraph-div-centered-relatively im-the-wild-one-touch-feel-enjoy-text-container">
                <p className="im-the-wild-one-touch-feel-enjoy-text">
                  {labels.imTheWildOneTouchFeelEnjoy}
                </p>
              </div>
            )
          }
        ]
      : [
          {
            type: "image",
            weight: 100,
            src: "/images/im-the-wild-one/mustang-body-detail.jpg",
            text: isSmallScreen ? undefined : labels.imTheWildOneTouchFeelEnjoy,
            textClass: "im-the-wild-one-touch-feel-enjoy-text",
            alt: labels.imTheWildOneMustangBodyAlt,
            imgTitle: labels.mustangImgTitle
          }
        ]
  },
  {
    type: "grid",
    orientation: "horizontal",
    margin: isSmallScreen,
    grid: [
      {
        type: "image",
        weight: 68.5,
        src: "/images/im-the-wild-one/mustang.jpg",
        title: labels.imTheWildOneMustangTitle,
        alt: labels.imTheWildOneMustangAlt,
        imgTitle: labels.mustangImgTitle
      },
      {
        type: "image",
        weight: 30,
        src: "/images/im-the-wild-one/mustang-leg-detail.jpg",
        alt: labels.imTheWildOneMustangLegsAlt,
        imgTitle: labels.mustangImgTitle
      }
    ]
  },
  {
    type: "grid",
    orientation: "horizontal",
    margin: isSmallScreen,
    grid: [
      {
        type: "image",
        weight: 100,
        src: "/images/im-the-wild-one/im-the-wild-one-zebra-chair.jpg",
        title: labels.imTheWildOneImTheWildOneTitle,
        alt: labels.imTheWildOneImTheWildOneAlt,
        imgTitle: labels.imTheWildOneImgTitle
      }
    ]
  },
  {
    type: "grid",
    orientation: "horizontal",
    margin: isSmallScreen,
    grid: [
      {
        type: "image",
        weight: 68.5,
        src: "/images/im-the-wild-one/easy-rider-tail-plug-detail.jpg",
        title: labels.imTheWildOneBornToBeWildTitle,
        titleClass: "im-the-wild-one-born-to-be-wild-img-title img-title",
        alt: labels.imTheWildOneEasyRiderTailPlugAlt,
        imgTitle: labels.easyRiderImgTitle
      },
      {
        type: "image",
        weight: 30,
        src: "/images/im-the-wild-one/easy-rider-gif.gif",
        title: labels.imTheWildOneEasyRiderTitle,
        alt: labels.imTheWildOneEasyRiderBackAlt,
        imgTitle: labels.easyRiderImgTitle
      }
    ]
  }
]

export default function ImTheWildOne({ labels, ...props }) {
  const isSmallScreen = useScreenSize()

  return (
    <Page
      {...props}
      labels={labels}
      title={labels.imTheWildOne}
      theme={themes(labels).ipsWhite(true)}
      description={labels.imTheWildOneDescription}
      keywords={labels.imTheWildOneKeywords}
      ogUrl={paths(labels).imTheWildOne}
      ogImage="/images/og-images/im-the-wild-one.jpg"
      footerPadding
    >
      <ScrollDown bigScreenOnly={true} />
      <ResponsiveImageGrid grid={imTheWildOneGrid(labels, isSmallScreen)} />
    </Page>
  )
}
